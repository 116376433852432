(function () {
  'use strict';

  /* @ngdoc object
   * @name facilities.newFacility
   * @description
   *
   */
  angular
    .module('neo.home.facilities.newFacility', []);
}());
